import React, {useState, useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import { FaAngleDoubleDown } from "@react-icons/all-files/fa/FaAngleDoubleDown";
import { FaAngleDoubleUp } from "@react-icons/all-files/fa/FaAngleDoubleUp";
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const StandardPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const infplans_big_logo = data.infplans_big_logo?.childImageSharp?.fluid
  const section2_img_right = data.section2_img_right?.childImageSharp?.fluid
  const section2_img_left = data.section2_img_left?.childImageSharp?.fluid
  const book_icon = data.book_icon?.childImageSharp?.fluid
  const book2_icon = data.book2_icon?.childImageSharp?.fluid
  const cta_bg = data.cta_bg?.childImageSharp?.fluid
  const [infBMIStatus, setInfBMIStatus] = useState(false);

  const phoneNumber = meta.phoneNumber
  const emailAddress = meta.emailAddress
  const emailAddressUrl = "mailto:"+emailAddress
  const phoneNumberUrl = "tel:"+phoneNumber


  function showInfBMI() {
    setInfBMIStatus(true);
  }

  function hideInfBMI() {
    setInfBMIStatus(false);
  }

  return (
    <Layout location={location}>
      <Helmet>
        <title>Hop! Mindoro - Low Cost Travel Assistance</title>
        <meta name="description" content="Hop! Mindoro is a low cost travel assistance package which covers pre-existing conditions"/>
        <meta name="keywords" content="Hop! Mindoro, inf insurance, cheap insurance for visitors, kv rao insurance, kvrao insurance,insurance for visitors, visitor insurance usa, accident &amp; sickness insurance for visitors to usa, accident &amp; sickness insurance for visitors to usa, visitor accident &amp; sickness insurance usa, visitor accident &amp; sickness insurance, visitor insurance for parents, insurance for visitors for parents"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="standard-hero-section-bg"
        fluid={hero_background}
        alt="standard"
      >
        <div id="standard-hero-section">
          <Container>
            <div className="col-lg-12">
              <h1 className="text-white text-center">
                HOP! MINDORO Travel Assist 
              </h1>
              <p className="hero-text text-white text-center">
                              Hop! Mindoro is travel assistance membership available to anyone traveling worldwide outside their home country.
             </p>
                          <p className="hero-text text-white text-center">
                              Hop! Mindoro travel assistance is available to travelers visiting anywhere outside their home country
              </p>


              <Link to="/inf-easy-select" className="nav-link">
                <button className="hero-btn"> Get a Quote </button>
              </Link>
            </div>
          </Container>
        </div>
      </BackgroundImage>

      <section id="standard-section1">
        <Container>
          <div className="standard-container">
            <h2 className="text-blue text-center">Hop! Mindoro </h2>
            <div className="row mt-5">
              <div className="col-lg-8">
                              <p className="text-black">Hop! Mindoro is a travel assistance membership which provides travel, medical, legal, and personal assistance for travelers visiting anywhere worldwide outside their home country.
                                 This asssitance membership provide assistance for eligible travel, medical (including pre-existing conditions), legal, and personal expenses for covered events, as per the terms and conditions listed in the membership subscription. Hop! Mindoro is travel assistance membership program, and is not an insurance product.</p>
                              <p className="text-black">Hop! Mindoro features Hop! Assist which arranges for direct billing with in-network providers on behalf of members. Hop! Assist provides 24/7 responsive claims, emergency travel and medical assistance from any device, any time, any place. Hop! Assist will process your claim, coordinate with the medical providers around the globe, determine eligibility, and even handle evacuation and repatriation services.</p>
				
                {infBMIStatus ?
                  <>
                                      <p>
                                          At Hop!, we understand that unexpected situations can arise while traveling, which is why we offer a range of services to help you navigate any travel-related issues that may come your way. Here are some of the features we offer to ensure that your travel plans stay on track:  </p>

                                      <p> Trip Cancellation:</p>
                                      <p>  We know that sometimes plans change, and you may need to cancel your trip. Our program offers trip cancellation assistance and provides reimbursement for non-refundable expenses. You have the ability to choose the level of Trip Cancellation assistance based on your membeship selection.</p>

                                      <p> Trip Delay:</p>
                                      <p>If your trip is delayed, we can help. Our program offers trip delay coverage to provide reimbursement for additional expenses incurred due to the delay, such as meals, transportation, and accommodations.</p>

                                          <p>Missed Connection / Coordination:</p>
                                      <p>If you miss a connection due to a delayed or cancelled flight, we can help coordinate alternative travel arrangements to get you to your destination as quickly as possible.</p>

                                          <p> Baggage Delay / Lost Bag Search:</p>
                                      <p> If your baggage is delayed, we can help you locate it and arrange for delivery to your location. Our program also offers coverage for expenses incurred due to baggage delay, such as the purchase of necessary clothing and toiletries.</p>

                                      <p> Lost Baggage / Lost Bag Search:</p>
                                      <p> If your baggage is lost, our program offers coverage to help replace your belongings and reimburse you for any necessary expenses incurred</p>

                                     <p>  Flight Rebooking:</p>
                                      <p>  If your flight is cancelled or delayed, we can help rebook your travel arrangements to get you to your destination as quickly and efficiently as possible.</p>


                                     <p> Legal Assistance:</p>
                                      <p> Our legal assistance team is available to provide you with legal advice and support while you travel. Whether you have questions about local laws and regulations, need assistance with visa or immigration issues, or require representation in legal proceedings, we have you covered</p>

                                      <p> Personal Assistance:</p>
                                      <p> Our personal assistance team is available to help with a wide range of non-medical needs while you travel. Whether you need help with language translation, making reservations or recommendations for restaurants and attractions, or arranging transportation, we are here to assist you.</p>

                                     <p> Medical Assistance:</p>
                                      <p>  Our medical assistance team is available to provide you with expert medical advice and support while you travel. We offer comprehensive medical assistance, including coverage for pre-existing conditions. If you require medical treatment while abroad, we can help arrange appointments with local doctors and hospitals, and even arrange medical transportation back to your home country if necessary.</p>
                                    <p>If you a visitors traveling within the United States, Hop! Minodoro utilizes the United Healthcare Options PPO via Hop! Assist.</p>
                                     <p>This assistance membership is available to visitors ages 0-99 traveling to anywhere worldwide when purchased before the start date of your trip for at least 30 days and no more than 364 days, which is the Maximum Legnth of Membership. Enrollment can be completed Online.</p>
                                    
                <a href="javascript:void(0)" className="read-less" onClick={hideInfBMI}>READ LESS <FaAngleDoubleUp className="double-arrow-up" /></a>
              </>
              :
                <a href="javascript:void(0)" className="read-more" onClick={showInfBMI}>READ MORE <FaAngleDoubleDown className="double-arrow-down" /></a>
              }
            </div>
              <div className="col-lg-4">
                <Img fluid={infplans_big_logo} className="big-logo"/>
                <h3 className="text-blue text-center">HOP MINDORO TRAVEL ASSIST</h3>
                <Link to="/inf-easy-select"><button className="row-btn"> Click to Get a Quote </button></Link>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="standard-section2">
        <Container>
          <div className="col-lg-12">
            <h3 className="text-white text-center">As a member of Hop! Mindoro assist, you will also have access to additional Features when traveling to the worldwide including:</h3>
          </div>
          <div className="col-lg-12">
            <div className="row mt-5">
              <div className="col-lg-6">
                <Img fluid={section2_img_right} className="section2-img1-mobile"/>
                <ul>
          <li className="text-white">Mindoro Travel Assitance program is a cost effective fixed assistance program. Unlike other travel assistance programs, Mindoro offers a set amount of assistanc for various covered events.   </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <Img fluid={section2_img_right} className="section2-img1"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row mt-5">
              <div className="col-lg-5">
                <Img fluid={section2_img_left} className="section2-img2"/>
              </div>
              <div className="col-lg-7">
                <ul>
                  <li className="text-white">As a plan member, you will be provided access to Doctor Please!- a telehealth feature which allows members to get medical care from the comfort of their own home. While anywhere worldwide, Doctor Please! allows members to schedule an appointment with a healthcare provider that is credentialed, certified and highly trained, and typically arrange medical care within 1 hour. The doctor will video or audio chat with you and if required, send a prescription to the nearest pharmacy (note that prescriptions may require out of pocket expense if they are not eligible expenses). Doctor Please! can be used for any non life threatening condition. This is not an insurance product and is not insured by Tideview Risk PCC. Doctor Please! only provides access to and helps arrange the telehealth visits.</li>
                </ul>
              </div>
            </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="row mt-5">
                          <div className="col-lg-6">
                              <Img fluid={section2_img_right} className="section2-img1-mobile" />
                              <ul>
                                  <li className="text-white">VIP airport assistance is a premium service offered as add-on to travelers that provides personalized, high-end support throughout the airport experience, when requested by the member and organized by INF-Robin Assist. This can include amenities such as fast-track immigration and security, private transportation to and from the plane, exclusive lounge access, and assistance with baggage and check-in. The goal of VIP airport assistance is to make the travel experience as seamless and comfortable as possible, while providing a high level of personal attention and luxury. There may be a fee associated with this service. This is an assistance feature and is not insurance. </li>
                              </ul>
                          </div>
                          <div className="col-lg-6">
                              <Img fluid={section2_img_right} className="section2-img1" />
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="row mt-5">
                          <div className="col-lg-5">
                              <Img fluid={section2_img_left} className="section2-img2" />
                          </div>
                          <div className="col-lg-7">
                              <ul>
                                  <li className="text-white">Airport delays and disruptions can be tiring and frustrating, and travellers rarely have access to immediate ways of reducing the stress caused. SmartDelay is offered to our members as an ideal solution for flight delays. Discover a different approach to assistance by offering an instantly redeemable benefit that eases the pain of flight delays, while enhancing our member experience. With SmartDelay, members can access a space that provides comfort, complimentary food and drink, and free Wi-Fi while they wait for their delayed flight for more than 90 minutes. This is an assistance feature and is not insurance.</li>
                              </ul>
                          </div>
                      </div>
                  </div>
        </Container>
          </section>



          <section id="standard-section8">
              <Container>
                  <div className="col-lg-12">
                      <h2 className="text-blue text-center">Hop! Mindoro Travel, Medical, & Security Assistance</h2>
                      <div className="standard-coverages-tab">
                          <input checked="checked" id="standard-coverages-tab1" type="radio" name="standard-coverages" />
                          <input id="standard-coverages-tab2" type="radio" name="standard-coverages" />
                          <nav>
                              <ul>
                                  <li className="standard-coverages-tab1">
                                      <label for="standard-coverages-tab1">Hop! Mindoro Travel Assistance Features<br /> </label>
                                  </li>
                               
                              </ul>
                          </nav>
                          <section>
                              <div className="standard-coverages-tab1">
                                  <p className="text-black text-center tab-row-title">Hop! Mindoro Travel Assistance (All Membership Options)</p>
                                  <div className="row">
                                      <div className="col-lg-10">
                                          <div className="column-tab">
                                              <p className="text-white column-tab-title">Travel Assistance Features</p>
                                          </div>
                                          <p className="tab-content text-black">Trip Cancellation | $10,000 (Optional)</p>
                                          <p className="tab-content text-black">Trip Delay | $600 (Optional)</p>
                                          <p className="tab-content text-black">Missed Connection / Coordination | $500 (Optional)</p>
                                          <p className="tab-content text-black">Baggage Delay / Lost Bag Search | $500 (Optional)</p>
                                          <p className="tab-content text-black">Lost Baggage / Lost Bag Search | $1000 (Optional)</p>
                                          <p className="tab-content text-black">Flight Rebooking | INCLUDED</p>
                                          <p className="tab-content text-black">Hotel Rebooking | INCLUDED</p>
                                          <p className="tab-content text-black">Rental vehicle booking | INCLUDED</p>
                                          <p className="tab-content text-black">Lost Passport & Travel Docs | INCLUDED</p>
                                          <p className="tab-content text-black">Emergency Cash Transfer | INCLUDED</p>
                                          <p className="tab-content text-black">Guaranteed Hotel Check-in | INCLUDED</p>
                                          <p className="tab-content text-black">Roadside Assistance | INCLUDED</p>
                                          <p className="tab-content text-black">Up-to-the-minute Travel Delay Reports | INCLUDED</p>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-lg-10">
                                          <div className="column-tab">
                                              <p className="text-white column-tab-title">Security Assistance Features</p>
                                          </div>
                                          <p className="tab-content text-black">Malicious product tamper and contamination | INCLUDED</p>
                                          <p className="tab-content text-black">General travel risk and travel security | INCLUDED</p>
                                          <p className="tab-content text-black">Assistance in mass public disturbances, violent terrorist, or extremist incidents | INCLUDED</p>
                                          <p className="tab-content text-black">24/7 point of contact to report emergencies and get security and safety advice from duty managers | INCLUDED0</p>
                                          <p className="tab-content text-black">Assistance in cases of major disasters | INCLUDED</p>
                                          <p className="tab-content text-black">Emergency political and security evacuations | INCLUDED</p>
                                          <p className="tab-content text-black">Hijacking (any form of conveyance) | INCLUDED</p>
                                          <p className="tab-content text-black">Illegal detention and arrest | INCLUDED</p>
                                          <p className="tab-content text-black">Kidnap for ransom | INCLUDED</p>
                                          <p className="tab-content text-black">Monitoring and telephone advice throughout the crisis | INCLUDED</p>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-lg-10">
                                          <div className="column-tab">
                                              <p className="text-white column-tab-title">Medical Assistance Features</p>
                                          </div>
                                          <p className="tab-content text-black">Air Ambulance (Optional) | $50,000</p>
                                          <p className="tab-content text-black">Commerical Airline (including Medical Stretcher) | $20,000</p>
                                          <p className="tab-content text-black">Medical clearances & Fit-to-Fly Assessments | INCLUDED</p>
                                          <p className="tab-content text-black">Physician, hospital, dental, and vision referrals via Hop Assist | INCLUDED0</p>
                                          <p className="tab-content text-black">Return Travel Arrangement | INCLUDED</p>
                                          <p className="tab-content text-black">Emergency Prescription Replacement | INCLUDED</p>
                                          <p className="tab-content text-black">Medical Cost containment, expense recovery, and overseas investigation | INCLUDED</p>
                                          <p className="tab-content text-black">Dispatch of Doctor or Specialist | INCLUDED</p>
                                          <p className="tab-content text-black">Arrangement of visitor to bedside | INCLUDED</p>
                                          <p className="tab-content text-black">Medical Payment Arrangements | INCLUDED</p>
                                          <p className="tab-content text-black">Medical Bill Audit | INCLUDED</p>
                                          <p className="tab-content text-black">Shipment of Medical Records | $75</p>
                                          <p className="tab-content text-black">Medical Equipment Rental & Replacement | $1,000</p>
                                          <p className="tab-content text-black">In-Patient / Out-Patient Care Management | INCLUDED</p>
                                          <p className="tab-content text-black">Eyeglasses & Corrective lens replacement | INCLUDED</p>
                                      </div>
                                  </div>
                              </div>
                          </section>
                      </div>
                  </div>
              </Container>
          </section>


      <section id="standard-section3">
        <Container>
          <div className="col-lg-12">
            <h2 className="text-blue text-center">Hop! Mindoro Medical Assistance</h2>
            <p className="text-black text-center tab-row-title">Hop! Mindoro provides medical assistance for pre-existing conditions</p>
            <div className="standard-coverages-tab">
              <input checked="checked" id="standard-coverages-tab1" type="radio" name="standard-coverages" />
              <input id="standard-coverages-tab2" type="radio" name="standard-coverages" />
              <nav>
                <ul>
                  <li className="standard-coverages-tab1">
                    <label for="standard-coverages-tab1">Hop! Mindoro Medical Assistance Options<br/> <span className="text-black age-title">Age 0-69</span></label>
                  </li>
                  <li className="standard-coverages-tab2">
                                      <label for="standard-coverages-tab2">Hop! Mindoro Medical Assistance Options<br/> <span className="text-black age-title">Age 70-99</span></label>
                  </li>
                </ul>
              </nav>
              <section>
                <div className="standard-coverages-tab1">
                  <p className="text-black text-center tab-row-title">$25,000 Medical Assistance Level</p>
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Medical Assistance Access Expense</p>
                      </div>
                      <p className="tab-content text-black">$0</p>
                      <p className="tab-content text-black">$100</p>
                      <p className="tab-content text-black">$250</p>
                      <p className="tab-content text-black">$500</p>
                      <p className="tab-content text-black">$1,000</p>
                      <p className="tab-content text-black">$2,500</p>
                      <p className="tab-content text-black">$5,000</p>
                    </div>
                  </div>
                  <p className="text-black text-center tab-row-title">$50,000 Medical Assistance Level</p>
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Medical Assistance Access Expense</p>
                      </div>
                      <p className="tab-content text-black">0</p>
                      <p className="tab-content text-black">$100</p>
                      <p className="tab-content text-black">$250</p>
                      <p className="tab-content text-black">$500</p>
                      <p className="tab-content text-black">$1,000</p>
                      <p className="tab-content text-black">$2,500</p>
                      <p className="tab-content text-black">$5,000</p>
                    </div>
                  </div>
                                  <p className="text-black text-center tab-row-title">$75,000 Medical Assistance Level</p>
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Medical Assistance Access Expense</p>
                      </div>
                      <p className="tab-content text-black">$0</p>
                      <p className="tab-content text-black">$100</p>
                      <p className="tab-content text-black">$250</p>
                      <p className="tab-content text-black">$500</p>
                      <p className="tab-content text-black">$1,000</p>
                      <p className="tab-content text-black">$2,500</p>
                      <p className="tab-content text-black">$5,000</p>
                    </div>
                  </div>
                </div>
                <div className="standard-coverages-tab2">
                                  <p className="text-black text-center tab-row-title">$25,000 Medical Assistance Level</p>
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Medical Assistance Access Expense</p>
                      </div>
                                          <p className="tab-content text-black">$0</p>
                                          <p className="tab-content text-black">$100</p>
                                          <p className="tab-content text-black">$250</p>
                                          <p className="tab-content text-black">$500</p>
                                          <p className="tab-content text-black">$1,000</p>
                                          <p className="tab-content text-black">$2,500</p>
                                          <p className="tab-content text-black">$5,000</p>
                    </div>
                  </div>
                                  <p className="text-black text-center tab-row-title">$50,000 Medical Assistance Level</p>
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Medical Assistance Access Expense</p>
                      </div>
                                          <p className="tab-content text-black">$0</p>
                                          <p className="tab-content text-black">$100</p>
                                          <p className="tab-content text-black">$250</p>
                                          <p className="tab-content text-black">$500</p>
                                          <p className="tab-content text-black">$1,000</p>
                                          <p className="tab-content text-black">$2,500</p>
                                          <p className="tab-content text-black">$5,000</p>
                    </div>
                                  </div>
                                  <p className="text-black text-center tab-row-title">$75,000 Medical Assistance Level</p>
                                  <div className="row">
                                      <div className="col-lg-10">
                                          <div className="column-tab">
                                              <p className="text-white column-tab-title">Medical Assistance Access Expense</p>
                                          </div>
                                          <p className="tab-content text-black">$0</p>
                                          <p className="tab-content text-black">$100</p>
                                          <p className="tab-content text-black">$250</p>
                                          <p className="tab-content text-black">$500</p>
                                          <p className="tab-content text-black">$1,000</p>
                                          <p className="tab-content text-black">$2,500</p>
                                          <p className="tab-content text-black">$5,000</p>
                                      </div>
                                  </div>
                </div>
              </section>
            </div>
          </div>
        </Container>
      </section>

      <section id="standard-section4">
        <Container>
          <div className="col-lg-12">
          <h2 className="text-blue text-center">Hop! Mindoro Medical Assistance Schedule</h2>
          <p className="text-black text-center tab-row-title">$25,000 Membership Level with Pre-Existing Assistance</p>

            <div className="standard-benefits1-tab">
              <input checked="checked" id="standard-benefits1-tab1" type="radio" name="standard-benefits1" />
              <input id="standard-benefits1-tab2" type="radio" name="standard-benefits1" />
              <input id="standard-benefits1-tab3" type="radio" name="standard-benefits1" />
              <nav>
                <ul>
                  <li className="standard-benefits1-tab1">
                    <label for="standard-benefits1-tab1">In-Patient Assistance</label>
                  </li>
                  <li className="standard-benefits1-tab2">
                    <label for="standard-benefits1-tab2">Out-Patient Assistance</label>
                  </li>
                  <li className="standard-benefits1-tab3">
                    <label for="standard-benefits1-tab3">Other Medical Assistance</label>
                  </li>
                </ul>
              </nav>
              <section>
                <div className="standard-benefits1-tab1">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Medical Assistance Provided</p>
                      </div>
                      <p className="tab-content2 text-black">Hospital Room & Board</p>
                      <p className="tab-content2 text-black">Hospital Intensive Care Unit Room & Board</p>
                      <p className="tab-content text-black">Doctor Surgical Expenses</p>
                      <p className="tab-content text-black">Anesthetics</p>
                      <p className="tab-content text-black">Assistant Surgeon Expenses</p>
                      <p className="tab-content text-black">Doctor's Non-Surgical Treatment/Examination Expenses</p>
                      <p className="tab-content text-black">Pre-admission tests within 14 days before Hospital Admission</p>
                      <p className="tab-content text-black">Admission</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">In-Patient Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$2,700</p>
                      <p className="tab-content text-black">$2,100</p>
                      <p className="tab-content text-black">$2,000</p>
                      <p className="tab-content text-black">$500</p>
                      <p className="tab-content text-black">​$1,250</p>
                      <p className="tab-content text-black">$40 per visit, 1 visit per day, to a max of 10 visits</p>
                      <p className="tab-content text-black">$400</p>
                      <p className="tab-content2 text-black">$950</p>
                    </div>
                  </div>
                </div>
                <div className="standard-benefits1-tab2">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                      <p className="text-white column-tab-title">Medical Assistance Provided</p>
                      </div>
                      <p className="tab-content text-black">Surgical Room & Supply Expenses</p>
                      <p className="tab-content text-black">Hospital Emergency</p>
                      <p className="tab-content text-black">Doctor Surgical Expenses</p>
                      <p className="tab-content text-black">Anesthetics</p>
                      <p className="tab-content text-black">Assistant Surgeon Expenses</p>
                      <p className="tab-content text-black">Doctor's Non-Surgical Treatment/Examination Expenses</p>
                      <p className="tab-content text-black">X-rays & laboratory procedures</p>
                      <p className="tab-content text-black">CAT Scan, PET Scan, or MRI</p>
                      <p className="tab-content text-black">Prescription Drug Expenses</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Out-Patient Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$950</p>
                      <p className="tab-content text-black">$275</p>
                      <p className="tab-content text-black">$2,000</p>
                      <p className="tab-content text-black">​$500</p>
                      <p className="tab-content text-black">​$500</p>
                      <p className="tab-content text-black">$60 per visit | 1 visit per day <br />Up to 10 visits</p>
                      <p className="tab-content text-black">$275</p>
                      <p className="tab-content text-black">​$275</p>
                      <p className="tab-content text-black">$75</p>
                    </div>
                  </div>
                </div>
                <div className="standard-benefits1-tab3">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                      <p className="text-white column-tab-title">Medical Assistance Provided</p>
                      </div>
                      <p className="tab-content text-black">Ambulance Expenses</p>
                      <p className="tab-content text-black">Rehabilitative Braces or Appliances</p>
                      <p className="tab-content2 text-black">Dental Treatment (Injury )</p>

                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Other Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$375</p>
                      <p className="tab-content text-black">$500</p>
                      <p className="tab-content text-black">​$500</p>
                    </div>
                    <div className="column-tab">
                      <p className="text-white column-tab-title">Additional Benefits</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-content text-black">Emergency Medical Evacuation Benefit</p>
                      <p className="tab-content text-black">Repatriation of Remains Benefit</p>
                      <p className="tab-content text-black">Accidental Death & Dismemberment</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-content text-black">$20,000 maximum</p>
                      <p className="tab-content text-black">$15,000 maximum​</p>
                      <p className="tab-content text-black">$25,000 Principal Sum</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12 mt-4">
                      <p className="text-black text-center tab-row-title">$50,000 Membership Level with Pre-Existing Assistance</p>

            <div className="standard-benefits2-tab">
              <input checked="checked" id="standard-benefits2-tab1" type="radio" name="standard-benefits2" />
              <input id="standard-benefits2-tab2" type="radio" name="standard-benefits2" />
              <input id="standard-benefits2-tab3" type="radio" name="standard-benefits2" />
              <nav>
                <ul>
                  <li className="standard-benefits2-tab1">
                    <label for="standard-benefits2-tab1">In-Patient Assistance</label>
                  </li>
                  <li className="standard-benefits2-tab2">
                                      <label for="standard-benefits2-tab2">Out-Patient Assistance</label>
                  </li>
                  <li className="standard-benefits2-tab3">
                                      <label for="standard-benefits2-tab3">Other Assistance</label>
                  </li>
                </ul>
              </nav>
              <section>
                <div className="standard-benefits2-tab1">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Medical Assistance Provided</p>
                      </div>
                      <p className="tab-content2 text-black">Hospital Room & Board</p>
                      <p className="tab-content2 text-black">Hospital Intensive Care Unit Room & Board</p>
                      <p className="tab-content text-black">Doctor Surgical Expenses</p>
                      <p className="tab-content text-black">Anesthetics</p>
                      <p className="tab-content text-black">Assistant Surgeon Expenses</p>
                    <p className="tab-content text-black">Doctor's Non-Surgical Treatment/Examination Expenses</p>
                    <p className="tab-content text-black">Pre-Admission Tests within 14 days before hospital admission</p>
                    <p className="tab-content text-black">Admission</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">In-Patient Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$3,900</p>
                      <p className="tab-content text-black">$2,650</p>
                      <p className="tab-content text-black">$3,000</p>
                      <p className="tab-content text-black">$1,500</p>
                      <p className="tab-content text-black">$1,500</p>
                      <p className="tab-content text-black">$60 per visit, 1 visit per day, to 10 visits</p>
                      <p className="tab-content text-black">$1,000</p>
                      <p className="tab-content2 text-black">$1,100</p>
                    </div>
                  </div>
                </div>
                <div className="standard-benefits2-tab2">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                       <p className="text-white column-tab-title">Medical Assistance Provided</p>
                      </div>
                      <p className="tab-content text-black">Surgical Room & Supply Expenses</p>
                      <p className="tab-content text-black">Hospital Emergency</p>
                      <p className="tab-content text-black">Doctor Surgical Expenses</p>
                      <p className="tab-content text-black">Anesthetics</p>
                      <p className="tab-content text-black">Assistant Surgeon Expenses</p>
                      <p className="tab-content text-black">Doctor's Non-Surgical Treatment/Examination Expenses</p>
                      <p className="tab-content text-black">X-rays & laboratory procedures</p>
                      <p className="tab-content text-black">CAT Scan, PET Scan, or MRI</p>
                      <p className="tab-content text-black">Prescription Drug Expenses</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Out-Patient Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$1,000</p>
                      <p className="tab-content text-black">$350</p>
                      <p className="tab-content text-black">$3,000</p>
                      <p className="tab-content text-black">​$750</p>
                      <p className="tab-content text-black">​​$750</p>
                      <p className="tab-content text-black">$60 per visit| 1 visit per day <br/>Up to 10 visits</p>
                      <p className="tab-content text-black">$400</p>
                      <p className="tab-content text-black">$400</p>
                      <p className="tab-content text-black">$100</p>
                    </div>
                  </div>
                </div>
                <div className="standard-benefits2-tab3">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Medical Assistance Provided</p>
                      </div>
                      <p className="tab-content text-black">Ambulance Expenses</p>
                      <p className="tab-content text-black">Rehabilitative Braces or Appliances</p>
                      <p className="tab-content2 text-black">Dental Treatment (Injury )</p>
                      
                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Other Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$400</p>
                      <p className="tab-content text-black">$550</p>
                      <p className="tab-content text-black">​$550</p>
                     
                    </div>
                    <div className="column-tab">
                      <p className="text-white column-tab-title">Additional Benefits</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-content text-black">Emergency Medical Evacuation Benefit</p>
                      <p className="tab-content text-black">Repatriation of Remains Benefit</p>
                      <p className="tab-content text-black">Accidental Death & Dismemberment</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-content text-black">$20,000 maximum</p>
                      <p className="tab-content text-black">$25,000 maximum​</p>
                      <p className="tab-content text-black">$25,000 Principal Sum</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Container>

        <Container>
          <div className="col-lg-12 mt-4">
                      <p className="text-black text-center tab-row-title">$75,000 Membership Level with Pre-Existing Assistance</p>

            <div className="standard-benefits3-tab">
              <input checked="checked" id="standard-benefits3-tab1" type="radio" name="standard-benefits3" />
              <input id="standard-benefits3-tab2" type="radio" name="standard-benefits3" />
              <input id="standard-benefits3-tab3" type="radio" name="standard-benefits3" />
              <nav>
                <ul>
                  <li className="standard-benefits3-tab1">
                    <label for="standard-benefits3-tab1">In-Patient Assistance</label>
                  </li>
                  <li className="standard-benefits3-tab2">
                                      <label for="standard-benefits3-tab2">Out-Patient Assistance</label>
                  </li>
                  <li className="standard-benefits3-tab3">
                                      <label for="standard-benefits3-tab3">Other Assistance</label>
                  </li>
                </ul>
              </nav>
              <section>
                <div className="standard-benefits3-tab1">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Covered Medical Assistance</p>
                      </div>
                      <p className="tab-content2 text-black">Hospital Room & Board</p>
                      <p className="tab-content2 text-black">Hospital Intensive Care Unit Room & Board</p>
                      <p className="tab-content text-black">Doctor Surgical Expenses</p>
                      <p className="tab-content text-black">Anesthetics</p>
                      <p className="tab-content text-black">Assistant Surgeon Expenses</p>
                      <p className="tab-content text-black">Doctor's Non-Surgical Treatment/Examination Expenses</p>
                                          <p className="tab-content text-black">Pre-Admission Tests within 14 days before hospital admission</p>
                                          <p className="tab-content text-black">Admission</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">In-Patient Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$4,575</p>
                      <p className="tab-content text-black">$3,300</p>
                      <p className="tab-content text-black">$4,000</p>
                      <p className="tab-content text-black">$2,500</p>
                      <p className="tab-content text-black">$2,500</p>
                      <p className="tab-content text-black">$80 per visit, 1 visit per day, to 30 visits</p>
                      <p className="tab-content text-black">$1,050</p>
                      <p className="tab-content2 text-black">$1,050</p>
                    </div>
                  </div>
                </div>
                <div className="standard-benefits3-tab2">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Covered Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">Surgical Room and Supply Expenses</p>
                      <p className="tab-content text-black">Hospital Emergency</p>
                      <p className="tab-content text-black">Doctor Surgical Expenses</p>
                      <p className="tab-content text-black">Anesthetics</p>
                      <p className="tab-content text-black">Assistant Surgeon Expenses</p>
                      <p className="tab-content text-black">Doctor's Non-Surgical Treatment/Examination Expenses</p>
                      <p className="tab-content text-black">X-rays & laboratory procedures</p>
                      <p className="tab-content text-black">CAT Scan, PET Scan, or MRI</p>
                      <p className="tab-content text-black">Prescription Drug Expenses</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                                              <p className="text-white column-tab-title">Out-Patient Medical Assistance</p>
                      </div>
                      <p className="tab-content text-black">$1,050</p>
                      <p className="tab-content text-black">$425</p>
                      <p className="tab-content text-black">$4,000</p>
                      <p className="tab-content text-black">​$1,000</p>
                      <p className="tab-content text-black">​$1,000</p>
                      <p className="tab-content text-black">$80 per visit | 1 visit per day <br/>Up to 10 visits</p>
                      <p className="tab-content text-black">$525</p>
                      <p className="tab-content text-black">$425 additional</p>
                      <p className="tab-content text-black">$125</p>
                    </div>
                  </div>
                </div>
                <div className="standard-benefits3-tab3">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Covered Medical Services</p>
                      </div>
                      <p className="tab-content text-black">Ambulance Expenses</p>
                      <p className="tab-content text-black">Rehabilitative Braces or Appliances</p>
                      <p className="tab-content2 text-black">Dental Treatment (Injury )</p>


                    </div>
                    <div className="col-lg-6">
                      <div className="column-tab">
                        <p className="text-white column-tab-title">Other Medical Benefits</p>
                      </div>
                      <p className="tab-content text-black">$425</p>
                      <p className="tab-content text-black">$600</p>
                      <p className="tab-content text-black">​$550</p>

                    </div>
                    <div className="column-tab">
                      <p className="text-white column-tab-title">Additional Benefits</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-content text-black">Emergency Medical Evacuation Benefit</p>
                      <p className="tab-content text-black">Repatriation of Remains Benefit</p>
                      <p className="tab-content text-black">Accidental Death & Dismemberment</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-content text-black">$25,000 maximum</p>
                      <p className="tab-content text-black">$20,000 maximum​</p>
                      <p className="tab-content text-black">$45,000 Principal Sum</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Container>
      </section>

      <section id="standard-section5">
        <Container>
          <div className="col-lg-12">
            <h2 className="text-blue text-center">Hop! Mindoro General Conditions</h2>
          </div>
          <div className="col-lg-12 ">
            <div className="row mt-4 justify-content-center">
              <div className="col-lg-5 text-center">
                <h3 className="text-black">Read More for Age 0-69 </h3>
                <Img fluid={book_icon} className="book-icon-img"/>
                <a href="/policy_pdf/Hop_Mindoro_Assist_General_Conditions.pdf" target="_blank">
                  <button className="read-now-btn"> Read Now </button>
                </a>
              </div>
              <div className="col-lg-5 text-center">
                <h3 className="text-black">Read More for Age 70-99 </h3>
                <Img fluid={book2_icon} className="book-icon-img"/>
                              <a href="/policy_pdf/Hop_Mindoro_Assist_General_Conditions.pdf" target="_blank">
                  <button className="read-now-btn"> Read Now </button>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <BackgroundImage
        Tag="section"
        className="standard-cta-hero-section-bg"
        fluid={cta_bg}
        alt="cta"
      >
        <div id="standard-cta-section">
          <Container>
            <div className="col-lg-12">
              <h2 className="text-white text-center">Get Coverage Now</h2>
              <Link to="/apply"><button className="buy-now-btn"> Apply Now </button></Link>
            </div>
          </Container>
        </div>
      </BackgroundImage>

      <section id="standard-section7">
        <Container>
                    <div className="col-lg-12">
            <div className="blue-bg text-center">
              <h3 className="text-white">Privacy Statement:</h3>
              <p className="text-white">We know that your privacy is important to you and we strive to protect the confidentiality of your non-public personal information. We do not disclose any non-public personal information about our insureds or former insureds to anyone, except as permitted or required by law. We maintain appropriate physical, electronic and procedural safeguards to ensure the security of your non-public personal information. You may obtain a detailed copy of our privacy policy by calling 408-222-1110.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="white-bg text-center">
              <h3 className="text-blue">Complaints:</h3>
              <p className="text-black">In the event that you remain dissatisfied and wish to make a complaint you can do so to the Complaints team at {emailAddress}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="blue-bg text-center">
              <h3 className="text-white">Data Protection:</h3>
              <p className="text-white">Please note that sensitive health and other information that you provide may be used by us, our representatives, the insurers and industry governing bodies and regulators to process your insurance, handle claims and prevent fraud. This may involve transferring information to other countries (some of which may have limited, or no data protection laws). We have taken steps to ensure your information is held securely. Where sensitive personal information relates to anyone other than you, you must obtain the explicit consent of the person to whom the information relates both to the disclosure of such information to us and its use as set out above. Information we hold will not be shared with third parties for marketing purposes. You have the right to access your personal records.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="white-bg text-center">
              <h3 className="text-blue">Note:</h3>
              <p className="text-black">This travel assistance program is not an insurance and is not subject to and does not provide any insurance benefits required by the United States’ Patient Protection and Affordable Care Act (“PPACA”). This coverage is not a general health insurance product in any manner or kind. 
       </p>
     </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default StandardPage

export const query = graphql`
  query StandardPageQueryAndStandardPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        phoneNumber
        emailAddress
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    hero_background: file(name: { eq: "standard-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    infplans_big_logo: file(name: { eq: "infplans-big-logo" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_img_right: file(name: { eq: "standard-section2-img-right" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_img_left: file(name: { eq: "standard-section2-img-left" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book_icon: file(name: { eq: "book-icon" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book2_icon: file(name: { eq: "book2-icon" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cta_bg: file(name: { eq: "cta-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
